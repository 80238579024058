/**
 * @FilePath: /copyright/src/api/getData.js
 * @Description: 添加文件描述~~~
 * @Author: jiangshengkai@baidu.com
 * @Date: 2022-10-12 15:49:15
 * @LastEditors: jiangshengkai@baidu.com
 * @LastEditTime: 2023-03-16 10:09:53
 */
/**
 * @file api/getData接口文件
 *
*/
import { get, post } from '@/utils/http';
// 用户信息
export const getUserInfo = account => get('/api/userInfo', account);
// 获取反馈记录列表
export const repeat = account => get('/feedback/list', account);
// 查看反馈详情
export const getDetail = (account, id) => get('/feedback/detail/' + id, account);
// 上传解析excel
export const upExcel = (account, id) => post('/upload/excel', account);
// 发送验证短信
export const sendCode = (account, phone) => get('/upload/send/' + phone, account);
// 短信验证
export const verify = (params) => post('/user/verify', params);
// 对公打款
export const credit = (account) => get('/user/credit', account);
// 对公打款验证
export const result = (account) => get('/user/credit/result', account);
// 判定作品名称是否存在
export const judge = (account) => get('/ownership/judge', account);
// 权属模糊查询
export const keyword = (account) =>
  post('/ownership/keyword?' + 'page=' + account.page + '&size=' + account.size, account);
// 权属信息验证
export const ownverify = (account) => get('/ownership/ownverify', account);
// 验证身份证号
export const checkTwoFactor = (account) => post(`/identify/checkTwoFactor?name=${account.name}&id=${account.id}`);
// 获取二维码信息
export const getQrcode = (account) => post('/identify/getQrcode?name=' + account.name + '&id=' + account.id);
// 获取认证结果
export const getFaceResult = (account) => post('/identify/getFaceResult?taskId=' + account.taskId);
// 提交投诉
export const upload = (account) => post('/upload', account);
// 是否同意用户协议
export const agree = (account) => get('/user/agree?flag=true', account);
// 用户协议检查
export const checkAgree = (account) => get('/user/checkAgree', account);
// 获取用户信息
export const check = (account) => get('/login/check', account);
// 退出登录
export const utlogin = (account) => get('/?logout&u=http://copyright.baidu.com:8089', account);
// 获取权属信息
export const ownership = (account) => get('/ownership/' + account.id);
// 获取主体信息
export const info = (account) => get('/user/info', account);


// 批量链接URL校验
export const checkUrlList = (account) => post('/ownership/checkUrlList', account);