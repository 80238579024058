export default [
    {
        path: '/complaint',
        name: 'complaint',
        component: () => import('@/views/Complaint'),
    }, {
        path: '/confirm',
        name: 'confirm',
        component: () => import('@/views/Confirm'),
    }, {
        path: '/complete',
        name: 'complete',
        component: () => import('@/views/Complete'),
    }, {
        path: '/record',
        name: 'record',
        component: () => import('@/views/Record'),
    }, {
        path: '/redetail',
        name: 'redetail',
        component: () => import('@/views/Redetail'),
    }, {
        path: '/help',
        name: 'help',
        component: () => import('@/views/Help'),
    }, {
        path: '/verification',
        name: 'verification',
        component: () => import('@/views/Verification'),
    }, {
        path: '/agreement',
        name: 'agreement',
        component: () => import('@/views/Agreement'),
    }
];