/**
 * @FilePath: /copyright/src/router/index.js
 * @Description: 添加文件描述~~~
 * @Author: jiangshengkai@baidu.com
 * @Date: 2022-10-12 15:49:15
 * @LastEditors: jiangshengkai@baidu.com
 * @LastEditTime: 2023-02-07 18:29:46
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import complaint from './complaint.js';

Vue.use(VueRouter);
const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/index', // 主体认证
		name: 'index',
		component: () => import('@/views/Index'),
	},
	{
		path: '/subject', // 主体认证
		name: 'subject',
		component: () => import('@/components/subject/Subject'),
	},
	{
		path: '/ownership', // 权属
		name: 'ownership',
		component: () => import('@/components/ownership/Ownership'),
	},
	{
		path: '/hasownership', // 权属展示
		name: 'hasownership',
		component: () => import('@/components/ownership/HasOwnership'),
	},
	{
		path: '/noownership', // 权属暂无
		name: 'noownership',
		component: () => import('@/components/ownership/NoOwnership'),
	},
	...complaint,
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		};
	}
});
export default router;
