<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="app">
        <div v-if="isShownav" class="router">
            <router-view></router-view>
        </div>
        <!-- 固定头部页 -->
        <el-container v-if="!isShownav" class="page">
            <el-header style="height: 72px">
                <!-- 头部layoout -->
                <div class="head">
                    <!-- 左边logo 文字 -->
                    <router-link to="/"><img :src="logoIcon" alt="logo" /></router-link>
                    <!-- 右边登录注册 -->
                    <ul class="head_reight">
                        <li v-if="userData !== null">
                            <p class="user_login">
                                <el-avatar :src="userData.face_url" shape="square" :size="20"></el-avatar>
                                <el-dropdown>
                                    <el-button type="text">
                                        {{ userData.display_name }}
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown" placement="bottom">
                                        <el-dropdown-item>
                                            <p type="text" @click="account">
                                                <i class="iconfont">&#xe62d;</i>
                                                <span style="margin-left: 10px">我的反馈</span>
                                            </p>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <p type="text" @click="exit">
                                                <i class="iconfont">&#xe63a;</i>
                                                <span style="margin-left: 10px">退出</span>
                                            </p>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </p>
                        </li>
                    </ul>
                </div>
            </el-header>
            <!-- 主要内容区域 -->
            <el-main>
                <!-- 内容title -->
                <div class="el-main_nav">
                    <p v-show="isshow" class="btn" @click="account">
                        <i class="iconfont">&#xe605;</i>
                        返回
                    </p>
                    <p class="el-main_nav_title">{{ txt }}</p>
                </div>
                <router-view></router-view>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { check } from '@/api/getData.js';
export default {
    name: 'index',
    components: {},
    data() {
        return {
            logoIcon: require('@/assets/img/logo.png'),
            userData: {},
            routerpath: '',
            txt: '发起版权投诉',
            isshow: false,
        };
    },
    computed: {
        isShownav() {
            return this.routerpath === '/' || this.routerpath === '/agreement';
        },
    },
    methods: {
        account() {
            this.$router.push({
                path: '/record',
            });
        },
        exit() {
            let baseUrl = location.origin;
            let port = location.port;
            if (port) {
                window.location.href = 'https://passport.qatest.baidu.com/?logout&u=' + baseUrl;
            } else {
                window.location.href = 'https://passport.baidu.com/?logout&u=' + baseUrl;
            }
        },
    },
    watch: {
        $route: {
            handler(newRouter) {
                this.isshow = false;
                if (newRouter.name === 'record') {
                    this.txt = '反馈记录';
                } else if (newRouter.name === 'complete') {
                    this.txt = '完成投诉';
                } else if (newRouter.name === 'redetail') {
                    this.txt = '反馈详情';
                    this.isshow = true;
                } else {
                    this.txt = '发起版权投诉';
                }
                this.routerpath = location.pathname;
                const userInfo = sessionStorage.getItem('user');
                if (userInfo) {
                    this.userData = JSON.parse(userInfo);
                } else {
                    check().then((res) => {
                        const { data } = res;
                        if (res.data !== null) {
                            this.userData = data;
                            sessionStorage.setItem('user', JSON.stringify(data));
                        } else {
                            sessionStorage.removeItem('user');
                            this.userData = null;
                        }
                    });
                }
            },
            immediate: true,
        },
    },
};
</script>
<style>
.el-main {
    overflow: visible !important;
    height: auto !important;
    padding: 20px 0 24px 0 !important;
}
.el-form-item--small.el-form-item.last {
    margin-bottom: 0 !important;
}
.el-message {
    top: 88px !important;
}
.el-picker-panel__icon-btn {
    color: rgb(211, 209, 224) !important;
}
.el-picker-panel__icon-btn:hover {
    color: #409eff !important;
}
</style>
<style scoped>
::v-deep .el-pager li {
    line-height: 26px;
}
::v-deep .el-date-editor--daterange .el-range__close-icon {
    position: relative;
    right: -20px;
}
.router {
    overflow: hidden;
}
.page {
    width: 100%;
    height: 100vh;
}
::v-deep .el-upload-list.is-disabled .el-upload-list__item:hover .el-upload-list__item-status-label {
    display: none;
}
::v-deep .el-checkbox {
    font-weight: normal;
}
::v-deep .el-input__inner {
    color: #383d47;
}
::v-deep .el-date-editor .el-range-input {
    color: #383d47;
}
::v-deep .el-range-editor.is-disabled input {
    background-color: #f5f7fa;
    color: #c0c4cc;
    cursor: not-allowed;
}
::v-deep .el-form-item--small .el-form-item__error {
    padding-top: 4px;
}
::v-deep .el-select .el-input.is-focus .el-input__inner {
    border-color: #2d63e0;
}
::v-deep .el-input__inner:hover {
    border-color: #2d63e0;
}
::v-deep .el-range-editor.is-active {
    border-color: #2d63e0 !important;
}
.el-range-editor.is-active:hover {
    border-color: #2d63e0;
}
::v-deep .el-input input:focus {
    border: 1px solid #2d63e0;
}
::v-deep .el-select:hover .el-input__inner {
    border: 1px solid #2d63e0;
}
::v-deep .el-textarea textarea:focus {
    border: 1px solid #2d63e0;
}
::v-deep .el-checkbox__inner:hover {
    border-color: #2d63e0;
}
::v-deep .el-upload--picture-card:hover {
    border: 1px solid #2d63e0;
    color: #2d63e0;
}
::v-deep .el-textarea__inner:hover {
    border-color: #2d63e0;
}
::v-deep .el-input__inner {
    font-size: 14px;
}
::v-deep .el-button--primary {
    border-color: transparent !important;
}
::v-deep textarea {
    font-family: -apple-system, 'PingFang-SC-Regular', 'Microsoft YaHei', 'SimSun', 'Arial', sans-serif;
}
::v-deep .el-button--text {
    color: #1f1f1f;
}
.el-button--text:hover {
    color: #2d63e0 !important;
}
.el-dropdown-menu {
    padding: 10px 0 !important;
    top: 55px !important;
    border-radius: 10px;
}
.el-dropdown-menu__item i {
    margin: 0 0 0 5px;
    font-weight: bold;
}
.el-dropdown-menu__item p {
    line-height: 38px;
}
::v-deep .el-dropdown-menu__item {
    width: 110px !important;
    padding: 0 0 0 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 37px;
}
::v-deep .el-dropdown-menu__item:nth-child(1):hover {
    color: #2d63e0;
    background: #f5f7fc;
}
::v-deep .el-dropdown-menu__item:nth-child(2):hover {
    color: #e63232;
    background: #f5f7fc;
}
/* 头部样式 */
.el-header {
    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(29, 51, 92, 0.02), inset 0 -1px 0 0 rgba(215, 215, 215, 0.5);
    color: #333;
    text-align: center;
    line-height: 72px;
}
/* 头部layout */
.head {
    /* background-color: red; */
    width: 1200px;
    height: 72px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
}
.head img {
    width: 220px;
}
.head_reight {
    /* background-color: pink; */
    display: flex;
    justify-content: space-between;
}
.head_reight a {
    font-size: 14px;
    color: #1f1f1f;
    letter-spacing: 0;
    text-align: center;
}
.user_login {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.el-dropdown {
    margin-left: 10px;
}
/* 主要内容 */
.el-main {
    background-color: rgb(245, 247, 252);
    color: #333;
    text-align: center;
    /* line-height: 160px; */
    height: calc(100% - 150px);
}
.el-main_nav {
    display: flex;
    width: 1200px;
    height: 76px;
    margin: auto;
    /* background-color: pink; */
    margin-top: -20px;
    overflow: hidden;
    line-height: 76px;
}
.el-main_nav .btn {
    width: 64px;
    height: 28px;
    text-align: center;
    line-height: 27px;
    font-size: 14px;
    color: #767981;
    background: #e6eaf2;
    border-radius: 4px;
    margin: 25px 10px 0 0;
}
.el-main_nav .btn:hover {
    cursor: pointer;
    color: #2d63e0;
}
.el-main_nav .btn:hover i {
    color: #2d63e0;
}
.el-main_nav .btn i {
    display: inline-block;
    width: 12px;
    height: 12px;
    font-size: 12px;
    color: #767981;
    font-weight: bold;
    /* background: url(./assets/img/calendar.svg) no-repeat;
    background-size: 100% 100%; */
}
.el-main_nav_title {
    text-align: left;
    font-size: 24px;
    color: #1e2024;
    font-weight: bold;
}

/* 头部中线 */
.line {
    background: #d8d8d8;
    width: 1px;
    height: 16px;
    margin-top: 27px;
}
.el-dropdown-menu__item {
    width: 60px;
}
</style>
