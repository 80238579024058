/**
 * @FilePath: /copyright/src/store/index.js
 * @Description: 添加文件描述~~~
 * @Author: jiangshengkai@baidu.com
 * @Date: 2022-10-12 15:49:15
 * @LastEditors: jiangshengkai@baidu.com
 * @LastEditTime: 2023-02-07 18:29:38
 */
import Vue from 'vue';
import Vuex from 'vuex';
import { getUserInfo } from '@/api/getData';
// import {getLocalStorage} from ''

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		isLogin: false,
		active: 0, // 步骤状态
		cType: true, // 组织|个人
		pType: 1, // 1 权利人   2 代理人
		OwnImg: {}, // 权属图片
		own: false,
		countdown: {},
		instance: null,
		options: [
			{
				value: '1',
				label: '文字',
			},
			{
				value: '2',
				label: '图片',
			},
			{
				value: '3',
				label: '音乐',
			},
			{
				value: '4',
				label: '软件',
			},
			{
				value: '11',
				label: '软件(游戏)',
			},
			{
				value: '12',
				label: '软件(社交)',
			},
			{
				value: '13',
				label: '软件(工具)',
			},
			{
				value: '14',
				label: '软件(其它)',
			},
			{
				value: '15',
				label: '视听作品(短剧)',
			},
			{
				value: '5',
				label: '视听作品(影视)',
			},
			{
				value: '6',
				label: '视听作品(综艺)',
			},
			{
				value: '7',
				label: '视听作品(动漫)',
			},
			{
				value: '8',
				label: '视听作品(其他)',
			},
			{
				value: '9',
				label: '其他作品',
			},
		], // 作品类型
	},
	getters: {
		getOptionsArr: state => state.options,
		getOptionsObj: state => {
			let obj = {};
			(state.options || []).forEach(item => {
                obj[item.value] = item.label;
            });
			return obj;
		}
	},
	mutations: {
		changeUserLoginStatus(state, payload) {
			state.isLogin = payload;
		},
		islogin(state, params) {
			state.instance = params;
		},
		changeActive(state, params) {
			state.active = params;
		},
		changeType(state, params) {
			state.cType = params;
		},
		changePype(state, params) {
			state.pType = params;
		},
		changeOwn(state, params) {
			state.own = params;
		},
		upImg(state, params) {
			state.OwnImg[params.ident.id] = params;
		},
		countDown(state, payload) {
			state.countdown = payload;
		},
		changeOptions(state, payload) {
			state.options = payload;
		},
	},
	actions: {
		getUserInfo({ commit }) {
			getUserInfo().then(res => {
				// eslint-disable-next-line no-undef
				conmmit('changeUserLoginStatus', res.data);
				localStorage.setItem('userInfo', JSON.stringify(res.data));
			});
		}
	},
	modules: {
	}
});
