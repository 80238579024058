<!--
 * @FilePath: /copyright/src/views/Home.vue
 * @Description: 添加文件描述~~~
 * @Author: jiangshengkai@baidu.com
 * @Date: 2022-10-12 15:49:15
 * @LastEditors: jiangshengkai@baidu.com
 * @LastEditTime: 2023-02-07 17:44:05
-->
<template>
    <!-- 首页渲染 -->
    <div class="home">
        <home-wel></home-wel>
    </div>
</template>

<script>
import HomeWel from '@/components/HomeWel.vue';

export default {
    name: 'home',
    components: {
        'home-wel': HomeWel,
    },
};
</script>
<style scoped>
</style>
