const image1 = require('../img/help/image(1).png');
const image2 = require('../img/help/image(2).png');
const image3 = require('../img/help/image(3).png');
const image4 = require('../img/help/image(4).png');
const image5 = require('../img/help/image(5).png');
export default [{
    txt: '帐号主体信息登记',
    id: 0,
    con: `Q：发起投诉后，投诉人帐号主体信息如何填写？\n
    A：您只有注册并登陆百度帐号后才可以发起投诉，若您是权利人，在选择“组织”或“个人”后，按照投诉界面的要求填写主体信息并上传合法有效的权利人证明文件，包括企业营业执照、个人身份证原件等照片；<span style="font-weight: bold;">若您是代理人，受权利人的委托代其投诉，请一定注意在登记“投诉人帐号主体信息”时，要填写您作为代理人的组织或个人信息并上传文件，千万不要登记成您所代理的权利人信息。</span>\n
`
}, {
    id: 1,
    txt: '投诉注意事项',
    con: `
Q：对于人身权益类、商标权等非版权问题的侵权投诉，是否可以提交到版权投诉平台？\n
     A：版权投诉平台目前只处理百度旗下各产品所发生的版权侵权问题，若您有非版权类的投诉，请通过各产品自有的投诉渠道进行反馈。\n\n
     Q：如果投诉人没有法律专业背景，是否能在此平台进行版权侵权投诉呢？\n
     A：由于通过本平台发起侵权投诉，是一项正式的、严肃的、具有法律意义的行为，包括证明材料的选择和提交等流程，都会涉及大量中国大陆法律专业知识。若侵权投诉不能完全符合法定条件和形式，平台将无法处理。所以我们相信，如果您就侵权投诉事宜向法律专业人士进行咨询，或交由法律专业人士来处理，会有利于侵权投诉事宜更为顺畅地进行。\n`
}, {
    id: 2,
    txt: '投诉材料准备',
    con: `
Q：针对被侵权的不同作品类型，需要对应提供哪些权属证明文件？\n
     A：不同作品类型，对应上传的权属证明文件参见以下\n
     【软件类作品】：包括但不限于软件著作权登记证书、软件首页版权信息页面截图、著作权权利人授权书若您投诉的软件为网络游戏，还需补充提供主管机关出具的出版运营游戏批复文件、ISBN号核发单、运营单位授权书等。\n
     【文字类作品】：包括但不限于提供作品登记证书、创作初始文档的信息截图、作品首次公开发表或发行日期证明材料、首次发布平台页面截图、载有出版或版权标识的图书、著作权人授权合同或授权书\n
     【美术、摄影类作品】：包括但不限于作品登记证书、创作手稿信息截图、作品首次公开发表或发行日期证明材料、载有出版或版权标示的图书、著作权人授权合同或授权书\n
     【视听作品（电影）类作品】：包括但不限于电影公映许可证、影视出品方署名截图、著作权人授权合同或授权书\n
     【视听作品（电视剧）类作品】：包括但不限于电视剧发行许可证、影视出品方署名截图、著作权人授权合同或授权书\n
     【视听作品（漫画）类作品】包括但不限于作品登记证书、作品首次公开发表或发行日期证明材料、首次发布平台页面截图、创作手稿信息截图、著作权人授权合同或授权书、剧作出品方署名截图\n
     【视听作品（短视频）类作品】：包括但不限于创作初始文档的信息截图/后台截图、作品登记证书、作品首次公开发表或发行日期证明材料、首次发布平台页面截图、作品署名截图、著作权人授权合同或授权书/n`
}, {
    id: 3,
    txt: '投诉申请失败的常见理由',
    con: `
Q：在版权平台提交投诉申请后被审核人员拒绝了，主要会有哪些原因导致拒绝呢？\n
A：根据《民法典》《信息网络传播权保护条例》等法律规定，版权权利人因自身权利受到侵犯，需要通知网络服务提供者采取删除、屏蔽等必要措施的，该通知应当包括构成侵权的初步证据及权利人的真实身份信息。若权利人在投诉时未提交完整有效的证明材料，可能会被审核拒绝，较常见的拒绝原因包括以下几类：\n
1）以代理人的身份进行投诉，未提供有效的权利人主体资质证明文件，如权利人的身份证或营业执照。\n
2）以代理人的身份进行投诉，未提供在有效期之内的维权授权委托书。\n
3）填写的被侵权的作品名称，与上传的作品权属材料或侵权链接内容不一致。\n
4） 上传的作品权属证明文件不完整，比如未提供文字或视频类作品的原始著作权权属证明、未提供链条完整的授权书或授权合同。\n
5）被投诉的链接内容本身不涉及版权侵权，比如属于对作品的合理使用等。\n`
}, {
    id: 4,
    txt: '中国大陆以外主体提交投诉',
    con: `
Q：如果权利人是中国大陆以外的主体，是否可以在此平台进行投诉？\n
A：本平台支持权利人为大陆以外主体的侵权投诉，只是为确保涉外主体和材料的真实性，您需要以授权委托的方式委托中国大陆主体代为投诉。若证明材料是在外国或港澳台地区形成的，应按照法律规定在所在国家或地区进行公证认证或其他法律要求的证明程序。另注意所有涉外文件都必须提供中文翻译文本以及与原件确认无误的声明。\n`
}, {
    id: 5,
    txt: '百度对公验证说明',
    con: `
<h1 style="display: inline-block;
    font-size: 20px;
    font-weight: 600;
    ">验证方式说明：</h1>\n
<p style="text-indent: 1em;">百度通过对公账户向用户填写的对公账户进行人民币1-100分之间的某个随机数额的打款。在银行打款成功后，需要客户在验证页面中输入该打款金额。系统会校验金额的正确性，来确认客户对公账户的真实性。</p>
<p style="text-indent: 1em;">通过对公验证行为验证该账户与主体间的实际关联关系</p>\n
<h1 style="display: inline-block;
    font-size: 20px;
    font-weight: 600;
    ">验证流程说明：</h1>\n
<p>1、在【身份真实性认证】页面，点击「发起验证」按钮，开始认证</p>
<img width="100%" src=${image1}/>\n
<p>2、填写对公账户基本信息</p>
<img width="100%" src=${image2}/>\n
<p>3、点击保存并确认</p>
<img width="100%" src=${image3}/>\n
<p>4、等待银行打款\n等待银行打款，百度会向该账户打款1-100分之间的某个随机数额的，打款成功后状态将自行变更</p>\n
<p>5、填写打款金额\n需要客户在验证页面中输入该打款金额，提交后完成验证</p>
<img width="100%" src=${image4}/>\n
<p>6、身份验证通过\n用对公验证完成后，【身份真实性验证】页面将自动刷新状态，若状态未变更，请手动刷新浏览器，状态变更后点击「下一步」继续提交反馈</p>
<img width="100%" src=${image5}/>\n\n
<h1 style="display: inline-block;
    font-size: 20px;
    font-weight: 600;
    ">验证规则说明：</h1>\n
<p>1、打款：一次打款有三次验证机会，若用户三次填写银行信息错误或三次输入金额皆出现错误，导致验证失败，系统为保证企业客户账户安全将会对其进行冻结。冻结后用户可触发【解冻】，解冻失败后将永久冻结，永久冻结用户需要提交开户许可证进行解冻。</p>
<p>2、解冻：百度向用户对公账户再次打款，用户需要回填正确近8天内全部的验证金额，解冻成功</p>
<p>3、永久冻结：用户的3次核验失败，会触发账户的冻结；解冻会进行人工审核，若3次人工审核失败，会永久冻结，为保证账户安全，<span style="font-weight: bold;">将不支持该帐号对其主体进行认证。</span></p>`
},]