<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 首页 -->
    <div class="homewel">
        <div class="welcome">
            <div class="welcome_bgc">
                <div class="bg_wrap">
                    <div class="flex"></div>
                    <div class="bgimg">
                        <div class="flex1"></div>
                        <div class="flex2"></div>
                        <img :src="indexBgc" width="100%" />
                        <div class="welcome_bgc_nav">
                            <img :src="copyrightIcon" class="img1" />
                            <img :src="newIcon" class="img2" />
                            <p>
                                为了保护权利人的合法权益，百度公司升级全新版本的版权投诉<br />平台，面向广大网民，提供版权投诉服务
                            </p>
                            <el-button class="btn btn_send" @click="sendComplaint" @keyup="keyupBgc">
                                发起投诉
                            </el-button>
                            <el-dialog :visible.sync="centerDialogVisible" :show-close="showClo" center>
                                <span slot="title" class="dialog-title">版权投诉用户使用协议</span>
                                <p class="dialog-text">
                                    在您使用本平台各项服务之前，请务必审慎阅读并充分理解版权平台
                                    <router-link to="/agreement?id=1"> 《侵权投诉指引》 </router-link>、
                                    <router-link to="/agreement?id=2">《免责声明》</router-link> 、
                                    <router-link to="/agreement?id=3">《百度版权投诉平台用户服务协议》</router-link>
                                    的条款，如您已详细阅读并同意条款， 请点击【同意并继续】以开始使用我们的服务。
                                </p>
                                <div class="privacy-conent">
                                    <h1 class="privacy-title privacy-title-gap">《侵权投诉指引》</h1>
                                    <p class="first-line privacy-paragraph privacy-paragraph-gap">
                                        百度公司作为中立的平台服务提供方，高度重视版权保护问题，一直致力于建立和维护良性互动、健康有序的平台秩序。
                                        鉴于百度系产品中第三方提供的内容数量庞大，为了保护权利人的合法权益，根据相关法律法规的规定，我们专门开设了版权投诉平台，
                                        以高效、快速处理权利人的投诉。如果权利人在百度系产品中发现相关内容侵犯了其合法权益，
                                        权利人或权利人代理人（下文统称“您”）可以通过本指引示范的流程在百度版权投诉平台发起投诉，我们将尽快按照相关法规的规定进行处理。
                                    </p>
                                    <p class="first-line privacy-paragraph privacy-paragraph-gap">
                                        <strong>
                                            特别提醒并请理解，通过本平台发起侵权投诉，是一项正式的、严肃的、具有法律意义的行为，包括证明材料的选择和提交等流程，
                                            都会涉及大量中国大陆法律专业知识。若侵权投诉不能完全符合法定条件和形式，平台将无法处理。所以我们相信，
                                            就侵权投诉事宜向法律专业人士进行咨询，或交由法律专业人士处理，会有利于侵权投诉事宜更为顺畅地进行。
                                            在此真诚建议您在发起投诉前仔细阅读本指引，若您提交的投诉内容不符合以下规范，将无法通过审核，
                                            平台有权依违规情节的严重性采取相应的处罚措施，故请严格遵守平台规范发起投诉。
                                        </strong>
                                    </p>
                                    <h2 class="privacy-subtitle privacy-subtitle-gap">一. 投诉规则</h2>
                                    <h3 class="privacy-paragraph privacy-paragraph-gap">
                                        <strong>1. 投诉途径</strong>
                                    </h3>
                                    <p class="privacy-paragraph privacy-paragraph-gap">您有以下途径通知百度：</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        i.通过百度版权投诉平台（https://newcopyright.baidu.com/）根据页面提示填写投诉内容并上传相关证明材料；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        ii.通过邮寄的方式将书面投诉通知书及下述证明材料纸质版本邮寄至百度指定的以下地点：
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        地址：北京市海淀区上地十街百度大厦 法务部
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">邮编：100085</p>
                                    <h3 class="privacy-nested-title privacy-nested-title-gap">
                                        <strong>2. 投诉通知</strong>
                                    </h3>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        您需在投诉通知中提供包括但不限于以下内容：
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">（1）权利人主体信息</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        i.个人类投诉：需提供权利人姓名、证件号码、联系电话、邮箱，身份证正反面、手持身份证照片等；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        ii.组织类投诉：需提供权利人名称、证件号码、法人代表姓名和身份证号、联系人姓名和联系电话/邮箱，
                                        并上传营业执照或其他组织机构资质证明文件的清晰彩色原件扫描件或照片；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        iii.代他人投诉：除上述（i）（ii）中要求的权利人主体信息资料外，还需提供权利人向代理人出具的签名或盖章的授权委托书及代理人的真实身份材料。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">（2）投诉事项</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        提供被投诉内容的完整标题/名称和网络地址等足以准确定位侵权内容的相关信息，能详细描述您遇到的问题并指出侵权理由。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">（3）构成侵权的初步证明材料</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        您应提供的权属证明材料包括但不限于作品登记证书、创作手稿、作品首次公开发表或发行日期证明材料、公映许可证、
                                        载有出版或版权标识的图书、授权合同或授权书等能够有效证明权利人拥有相关权利的材料。若作品是通过网络平台进行首次发布，
                                        请提供网络平台首发链接或作品截图、帐号登录界面及后台注册信息截图。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        若您投诉软件类作品，需提供被投诉内容侵犯您软件类作品著作权的具体对比描述及著作权权属证明资料，包括软件著作权登记证书、
                                        软件首页版权信息页面截图、著作权权利人授权书等。若您投诉的软件为网络游戏，还需补充提供主管机关出具的出版运营游戏批复文件、ISBN号核发单、运营单位授权书等。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">（4）保证材料</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        您应在上传权属证明材料时一并提交投诉保证函，明确保证本次投诉内容和提供的证明材料皆是真实、有效和合法的，
                                        并保证承担和赔偿百度因根据投诉人的通知书对相关内容的处理而给百度造成的任何损失，包括但不限于百度因向被投诉方或用户赔偿而产生的损失及百度名誉、商誉损害等。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        如果您不确定被投诉内容是否侵犯了其合法权益，百度建议您首先咨询专业人士。
                                    </p>
                                    <h2 class="privacy-subtitle privacy-subtitle-gap">二. 平台反馈</h2>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        百度公司作为中立的平台服务者，收到符合本指引要求的投诉信息后，会尽快按照相关法律法规的规定进行审核处理，
                                        最终处理结果将在版权投诉平台“我的反馈”功能栏中及时告知。对于您提交的不符合本指引形式要求或缺少相关证明文件的投诉信息，
                                        平台会将该条投诉信息退回，并提示您按要求补充相关材料后重新投诉，请关注并及时查看“我的反馈”中的投诉处理状态和审核人员的反馈内容。
                                    </p>
                                    <h2 class="privacy-subtitle privacy-subtitle-gap">三. 注意事项</h2>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        1.
                                        本指引中的权利人，指拥有与投诉内容相关的版权等合法权益的原始所有人或经原始所有人合法授权的继受人，
                                        包括自然人、法人或其他组织等；本指引中的代理人，指接受权利人的委托，代为行使投诉权的机构或个人，包括律师、版权代理机构或其他组织等；
                                        本指引中的第三方，指已注册百度帐号并使用百度系产品服务的个人或机构用户；
                                        本指引中的帐号主体，指使用当前帐号的投诉人，可以是权利人或代理人身份。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2.
                                        为了保证证明材料的真实性和有效性，请您提供证明材料的原件。若不能提供原件，您需要提供由权利人签字或盖章的扫描件或复印件。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        若该投诉材料属于中国大陆以外（包括港澳台地区）形成的，您应按照当地的法律规定进行公证转递并提交转递材料。
                                        任何涉外的材料，均需提交中文翻译文本以及与原件确认无误的声明。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        若平台发现您存在提供虚假材料的行为，百度将不排除会采取限制和处罚措施，包括但不限于限制投诉、直接拒绝或处罚投诉帐号。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        3.
                                        为了更好地维护权利人的合法权益和正常的侵权投诉，同时保证系统的安全和正常运行，
                                        权利人及其代理人不得以可能严重影响其他用户合法权益、阻碍平台系统安全正常运行的方式进行投诉，包括但不限于：
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        （1）明知或者应知不构成侵权或者明显缺乏权利基础、事实依据而投诉的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        （2）平台已告知投诉无依据无法处理，而投诉人无实质性补充且仍大量重复投诉的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        （3）已提交多份或大量侵权投诉，但整体通过率低，涉嫌滥用投诉权益的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        （4）投诉理由不合理、目的不正当，对被投诉人或用户的权益造成不利影响，或对百度的名誉、商誉造成损害的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        （5）恶意使用外挂、插件、第三方软件、自动化程序等方式，阻碍平台安全正常运行的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        （6）以非正当目的，故意提供虚假材料包括伪造公章和资质证明文件等进行恶意投诉的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        （7）平台有理由相信存在不合理、滥用投诉权益的其他行为。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        一旦发现上述情况，平台不排除采取特殊处理机制或限制性措施，包括但不限于限制投诉、调整侵权投诉处理顺序或视情节严重性采取封号措施等。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        4.
                                        若您已经就被投诉人发布的内容向相关政府部门或法院提起投诉或诉讼的，请在提交投诉时，
                                        将相关受理证明以及证据材料一同提交给百度版权投诉平台，这将有利于您的投诉处理。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        5.
                                        百度版权投诉平台仅受理关于侵犯著作权及相关合法权益的投诉，对于肖像权、名誉权、隐私权等人格权或者商标权、
                                        专利权及商业秘密等其他知识产权的投诉，您可以按照百度公布的其他线上途径进行投诉，
                                        或者将书面通知书及相关证明文件的纸质版本一并邮寄至百度指定的以下地点：
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        <strong> 地址： </strong>
                                        北京市海淀区上地十街10号百度大厦 法务部
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        <strong> 邮编： </strong>
                                        100085
                                    </p>
                                    <h1 class="privacy-title privacy-title-gap">《免责声明》</h1>
                                    <p class="first-line privacy-paragraph privacy-paragraph-gap">
                                        <strong>
                                            百度提醒您：在使用百度版权投诉平台服务前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用百度版权投诉平台的服务，
                                            但如果您使用本平台，则您的使用行为将被视为对本平台声明全部内容的认可。
                                        </strong>
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        1
                                        您理解并同意：为了向您提供有效的服务，本平台会利用您终端设备的处理器和带宽等资源。
                                        本平台使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2
                                        您理解并同意：在使用本平台的服务时，须自行承担如下百度不可掌控的风险内容，包括但不限于：
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2.1
                                        由于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏等不可抗拒因素可能引起的信息丢失、泄漏等损失和风险；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2.2
                                        用户或百度的电脑软件、系统、硬件和通信线路出现故障导致的服务终端、数据丢失以及其他的损失和风险；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2.3 用户操作不当或通过非百度授权的方式使用本平台服务所带来的损失和风险；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2.4
                                        由于网络信号不稳定等原因，所引起的百度版权投诉平台登录失败、资料同步不完整、页面打开速度慢等风险；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2.5 其他百度无法控制或合理预见的情形。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        3
                                        百度依据本协议约定获得处理违法违规内容或行为的权利，该权利不构成百度的义务或承诺，百度不能保证及时发现违法违规情形或进行相应处理。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        4
                                        您理解并同意，本协议旨在保障遵守国家法律法规、维护公序良俗，保护用户和他人合法权益，百度在能力范围内尽最大的努力按照相关法律法规进行判断，
                                        但并不保证百度的判断完全与司法机关、行政机关的判断一致，如因此产生的后果您已经理解并同意自行承担。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        5
                                        您理解并同意：因业务发展需要，百度保留单方面对本服务的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销的权利，用户需承担此风险。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        6
                                        您理解并同意：在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、
                                        不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、
                                        风暴等以及社会事件如战争、动乱、政府行为等任何其它类似事件。
                                    </p>
                                    <h1 class="privacy-title privacy-title-gap">《百度版权投诉平台用户服务协议》</h1>
                                    <p class="first-line privacy-paragraph privacy-paragraph-gap">
                                        本协议缔约方为百度版权投诉平台实名认证用户（即签署了《百度版权投诉平台用户服务协议》的网络用户，下称“您”）与百度公司（下称“百度”）。
                                    </p>
                                    <p class="first-line privacy-paragraph privacy-paragraph-gap">
                                        百度提醒您：在使用百度版权投诉平台前，请您务必仔细阅读并透彻理解本协议。
                                        您可以选择不使用百度版权投诉平台，但如果您使用此平台，阅读并同意本协议，即被视为对本协议全部内容的认可。
                                        阅读协议的过程中，如果您不同意相关协议或其中任何条款约定，您应立即停止使用平台服务。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        <strong> 第一条总则 </strong>
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        1、百度版权投诉平台是由百度所有和运营的，为用户提供版权投诉服务的在线平台（以下简称“版权投诉平台”）。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2、版权投诉平台用户服务协议（以下简称“本协议”）是您与百度就版权投诉服务订立的在线协议。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        3、本协议可由百度根据需要进行更新，您应当及时关注并同意。版权投诉平台的通知、公告、声明或其他规则内容属于本协议的重要组成部分。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        <strong> 第二条帐号注册及管理 </strong>
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        1、用户资格：您通过帐号注册和登陆程序使用版权投诉平台的服务时，应具备中华人民共和国法律规定的与该行为相适应的民事行为能力。
                                        若您不具备前述与行为相适应的民事行为能力，则您或您的监护人应依照法律规定承担因此而导致的一切后果。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2、实名认证：在注册、使用和管理平台帐号时，请您务必填写和上传真实、准确、合法、有效的主体信息和证明材料，
                                        个人类包括您的姓名、身份证号、身份证件扫描件、联系方式，组织类包括您所在机构的名称、机构证件扫描件和证件信息、
                                        法人代表信息及联系人信息，并注意及时更新，以便百度在必要时与您联系。
                                        为使您更好地使用版权投诉平台的各项服务，请您按照相关法律规定及版权投诉平台的要求完成身份真实性验证。
                                        您应当对您提供的帐号资料的真实性、合法性、准确性和有效性独立承担责任。如因此给百度或第三方造成损害的，您应当依法予以赔偿。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        3、帐号登陆：使用百度版权投诉平台需要您注册并登陆百度帐号，当您阅读并同意本协议、
                                        按照页面提示填写帐号主体信息、且完成全部认证程序后，即可使用版权投诉平台的各项服务。
                                        您登陆并成为版权投诉平台的用户后，即受本协议的约束。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        4、帐号管理：设置的用户名不得违反国家法律法规，否则版权投诉平台有权收回或禁用您的帐号。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        5、帐号信息：您应按版权投诉平台的要求填写注册信息或提交资料，并应确保所填写的信息或资料真实、完整、准确、有效，如有变化，
                                        应及时更新相关信息或资料。如因您未及时变更有关信息或资料所导致的法律后果和损失，将由您自行承担，概与百度无关。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        6、帐号安全：您的帐号由您自行设置并妥善保管，您应对您帐号项下所有行为结果负责，
                                        任何因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担，概与百度无关。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        <strong> 第三条用户承诺与保证 </strong>
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        1、您自愿申请成为版权投诉平台用户并遵守版权投诉平台的各项规则和服务规范，包括但不限于《百度版权投诉平台用户服务协议》等。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2、您确认已阅读并自愿遵守我国版权保护相关的法律法规、各项部门规章制度及百度相关的平台规则，
                                        并接受百度关于版权投诉平台相关规则的修订，修订内容会在网页中显著的位置予以发布。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        3、您保证对提供的内容负全部责任，不得在版权投诉平台内提交含有下列行为之一的内容，否则版权投诉平台有权自行处理并不予以通知：
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">(1)违反宪法确定的基本原则的；</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        (2)危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">(3)损害国家荣誉和利益的；</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        (4)煽动民族仇恨、民族歧视，破坏民族团结的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        (5)破坏国家宗教政策，宣扬邪教和封建迷信的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        (6)散布谣言，扰乱社会秩序，破坏社会稳定的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        (7)散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        (8)侮辱或者诽谤他人，侵害他人合法权益的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        (9)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        (10)以非法民间组织名义活动的；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        (11)含有法律、行政法规禁止的其他内容的。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        4、百度版权投诉平台不会编辑或修改用户上传的各项信息和文件资料，您应保证对通过版权投诉平台进行版权投诉的一切作品均享有完整的知识产权，
                                        因违反本条规定而造成第三方争议、索赔、赔偿、诉讼的，由您自行承担责任，与版权投诉平台无关。
                                        因违反本条规定而造成第三方向百度公司索赔的，您应补偿百度公司因此承担的一切损失。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        5、您确保将按照我国法律法规及版权投诉平台规则行使通过版权投诉平台获得的一切权利，因您不当使用而造成的一切后果，由您自行承担。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        6、您应确保用于登陆的设备的安全性和可靠性。因您的设备故障、通讯故障等原因造成的法律后果和经济损失，均由您自行承担。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        7、您承诺将妥善保管帐号、密码及相关密保文件，若因泄密而造成的损失，您将自行承担相应责任。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        <strong> 第四条百度承诺及保证 </strong>
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        1、百度承诺严格遵守国家法律法规、各项部门规章制度的规定，并愿意接受本协议所有条款的约束。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2、保护用户个人信息是百度公司的一项基本原则，百度承诺将会运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，
                                        未经用户许可百度公司不会向第三方公开、透露用户个人信息，除以下法律法规规定的情形外：
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">（1）事先获得您的明确授权；</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        （2）根据司法机关或政府主管部门的要求；
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">（3）为维护社会公众的利益；</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">（4）其他法律法规要求的情形。</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        <strong> 第五条协议的变更 </strong>
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        我们会根据有关法律法规的变化、公司经营状况、经营策略调整和平台升级等原因修订本协议，并会在网页中的显著位置予以发布。
                                        更新后的协议条款公布后即有效代替原来的协议条款，您可随时使用本平台查阅最新版的协议条款。
                                        如果您不同意协议改动内容，请您立即停止使用本平台及服务。如果您继续使用，则视为接受本协议的改动。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        <strong> 第六条协议的终止 </strong>
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        1、若您违反本协议或版权投诉平台的其他规则，平台有权依规则对您的帐号采取相应的处罚措施，视情节严重性不排除终止您使用平台帐号或服务。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2、终止服务后，百度版权投诉平台有权将您在平台内的任何内容加以移除并删除。
                                        百度版权投诉平台对于终止您的帐号及删除相关信息、文件均不承担任何责任。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        <strong> 第七条通知 </strong>
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        您同意版权投诉平台以以下合理的方式发送的通知，均视为成功送达通知：
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">（一）公示的文案；</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">（二）网站推送的消息；</p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        （三）根据您预留于版权投诉平台的联系方式发出的电子邮件、短信、函件等。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        <strong> 第八条法律适用、管辖与其他 </strong>
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        1、因本协议产生的一切争议的解决均适用中华人民共和国法律。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        2、有关本协议的争议应通过友好协商解决。如果协商不成，任何一方均有权向北京市海淀区人民法院提起诉讼。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        3、如本协议的任何条款被视作无效或无法执行，不影响其余条款的法律效力。
                                    </p>
                                    <p class="privacy-paragraph privacy-paragraph-gap">
                                        4、百度对本协议及百度版权投诉平台等产品的所有内容在法律范围内享有解释权。
                                    </p>
                                </div>
                                <span slot="footer" class="dialog-footer">
                                    <el-button class="button1" @click="agreeChange(false)">不同意</el-button>
                                    <el-button type="primary" class="button2" @click="agreeChange(true)">
                                        同意并继续
                                    </el-button>
                                </span>
                            </el-dialog>
                        </div>
                    </div>
                    <div class="flex"></div>
                </div>
                <div class="welcome_head">
                    <!-- 左边logo 文字 -->
                    <router-link to="/"><img :src="logoIcon" alt="logo" /></router-link>
                    <!-- 右边登录注册 -->
                    <ul class="welcome_head_reight">
                        <li v-if="userData === null">
                            <a id="head_reight_login" @click="login">登录</a>
                        </li>
                        <li v-if="userData !== null">
                            <p class="user_login">
                                <el-avatar :src="userData.face_url" shape="square" :size="20"></el-avatar>
                                <el-dropdown>
                                    <el-button type="text">
                                        {{ userData.display_name }}
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown" placement="bottom">
                                        <el-dropdown-item>
                                            <p type="text" @click="account">
                                                <i class="iconfont">&#xe62d;</i>
                                                <span style="margin-left: 10px">我的反馈</span>
                                            </p>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <p type="text" @click="exit">
                                                <i class="iconfont">&#xe63a;</i>
                                                <span style="margin-left: 10px">退出</span>
                                            </p>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </p>
                        </li>
                        <li v-if="userData === null" class="line"></li>
                        <li v-if="userData === null">
                            <a :href="href">注册</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="welcome_net">
                <div class="welcome_net_title">版权投诉支持产品</div>
                <div class="welcome_net_font">
                    本平台仅受理著作权类投诉，其他类型的投诉不在本平台的受理范围内，请移步对应产品的官方投诉渠道。
                </div>
            </div>
            <div class="welcome_line">
                <ul v-for="item in productarr" :key="item[0].value">
                    <li v-for="items in item" :key="items.label">
                        <div>
                            <img :src="items.img" width="100%" height="100%" />
                        </div>
                        <p>{{ items.label }}</p>
                    </li>
                </ul>
            </div>
            <div class="welcome_flowbgc">
                <div class="flowbgc_wrap">
                    <div></div>
                    <img class="flowbgc" :src="flowIcon" width="1349px" height="704px" />
                    <div></div>
                </div>
                <div class="welcome_flow">
                    <div class="welcome_flow_title">版权投诉流程</div>
                    <div class="welcome_flow_font">如果您认为他人侵犯了您的版权，可以通过以下步骤进行投诉</div>
                    <ul class="welcome_flow_main">
                        <li>
                            <img :src="oneIcon" width="100%" height="100%" />
                            <div>登陆百度帐号</div>
                            <p>请登陆您的百度帐号</p>
                            <div class="cover">
                                <img :src="cardbgcIcon" width="100%" height="100%" />
                                <div class="cover_img">
                                    <img :src="cubeIcon" width="100%" height="100%" />
                                </div>
                                <div class="cover_font">
                                    <p class="cover1">登陆百度帐号</p>
                                    <p class="cover2">请登陆您的百度帐号</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <img :src="twoIcon" width="100%" height="100%" />
                            <div>帐号主体认证</div>
                            <p>请填写您的个人身份信息或组织机构信息并上传证明材料</p>
                            <div class="cover">
                                <img :src="cardbgcIcon" width="100%" height="100%" />
                                <div class="cover_img">
                                    <img :src="cubeIcon" width="100%" height="100%" />
                                </div>
                                <div class="cover_font">
                                    <p class="cover1">帐号主体认证</p>
                                    <p class="cover2">请填写您的个人身份信息或组织机构信息并上传证明材料</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <img :src="threeIcon" width="100%" height="100%" />
                            <div>登记权属信息</div>
                            <p>请填写被投诉的作品信息并提交完整有效的权属证明材料</p>
                            <div class="cover">
                                <img :src="cardbgcIcon" width="100%" height="100%" />
                                <div class="cover_img">
                                    <img :src="cubeIcon" width="100%" height="100%" />
                                </div>
                                <div class="cover_font">
                                    <p class="cover3">登记<br />权属信息</p>
                                    <p class="cover2">请填写被投诉的作品信息并提交完整有效的权属证明材料</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <img :src="fourIcon" width="100%" height="100%" />
                            <div>填写投诉链接</div>
                            <p>请填写投诉理由并添加被投诉内容的标题和链接地址</p>
                            <div class="cover">
                                <img :src="cardbgcIcon" width="100%" height="100%" />
                                <div class="cover_img">
                                    <img :src="cubeIcon" width="100%" height="100%" />
                                </div>
                                <div class="cover_font">
                                    <p class="cover1">填写投诉链接</p>
                                    <p class="cover2">请填写投诉理由并添加被投诉内容的标题和链接地址</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <img :src="fiveIcon" width="100%" height="100%" />
                            <div>确认投诉内容</div>
                            <p>对所填写和上传的内容进行确认并完成提交</p>
                            <div class="cover">
                                <img :src="cardbgcIcon" width="100%" height="100%" />
                                <div class="cover_img">
                                    <img :src="cubeIcon" width="100%" height="100%" />
                                </div>
                                <div class="cover_font">
                                    <p class="cover1">确认投诉内容</p>
                                    <p class="cover2">对所填写和上传的内容进行确认并完成提交</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="btnList">
                        <el-button class="btn btn_send" @click="sendComplaint" @keyup="keyupBgc">发起投诉</el-button>
                        <el-button class="point" @click="goRecord">我的反馈</el-button>
                    </div>
                </div>
                <div class="welcome_common">
                    <img class="problemIcon" :src="problemIcon" />
                    <div class="welcome_common_bgc clearfix">
                        <div class="welcome_common_problem clearfix">
                            <p>常见问题</p>
                            <img :src="whatIcon" width="28px" height="28px" />
                            <div>common problems</div>
                        </div>
                        <ul>
                            <router-link
                                v-for="item in helpList[0]"
                                :key="item.id"
                                :to="'/help?curid=' + (item.id + 1)"
                                tag="li"
                            >
                                <div></div>
                                {{ item.txt }}
                            </router-link>
                        </ul>
                        <ul>
                            <router-link
                                v-for="item in helpList[1]"
                                :key="item.id"
                                :to="'/help?curid=' + (item.id + 1)"
                                tag="li"
                            >
                                <div></div>
                                {{ item.txt }}
                            </router-link>
                            <router-link to="/help" tag="li" class="more">
                                查看更多
                                <i class="iconfont img">&#xe617;</i>
                            </router-link>
                        </ul>
                    </div>
                </div>
                <div class="welcome_footer">
                    <ul>
                        <a href="http://home.baidu.com/"> 关于百度 </a>
                        <a href="https://ir.baidu.com/investor-overview?c=188488&p=irol-irhome"> ABOUT BAIDU </a>
                        <router-link to="/agreement?id=1"> 侵权投诉指引 </router-link>
                        <router-link to="/agreement?id=2"> 免责声明 </router-link>
                        <router-link to="/agreement?id=3"> 用户服务协议 </router-link>
                    </ul>
                    <div class="welcome_footer_bottom">
                        京公网安备11000002000001号京ICP证030173号@2021 Baidu
                        (京)-经营性-2017-0020信息网络传播视听节目许可证 0110516
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { agree, check, checkAgree } from '@/api/getData.js';
import product from '@/assets/data/product.js';
import help from '@/assets/data/help.js';

export default {
    name: 'homewel',
    components: {},
    data() {
        return {
            productarr: [],
            helpList: [],
            userData: null,
            baseUrl: '',
            href: '',
            instance: null,
            indexBgc: require('@/assets/img/bgc2.png'),
            logoIcon: require('@/assets/img/logo.png'),
            copyrightIcon: require('@/assets/img/copyright.png'),
            newIcon: require('@/assets/img/new.png'),
            flowIcon: require('@/assets/img/flowbgc.png'),
            problemIcon: require('@/assets/img/problem.png'),
            whatIcon: require('@/assets/img/what.svg'),
            moreIcon: require('@/assets/img/more.svg'),
            oneIcon: require('@/assets/img/one.png'),
            twoIcon: require('@/assets/img/two.png'),
            threeIcon: require('@/assets/img/three.png'),
            fourIcon: require('@/assets/img/four.png'),
            fiveIcon: require('@/assets/img/five.png'),
            cardbgcIcon: require('@/assets/img/cardbgc.png'),
            cubeIcon: require('@/assets/img/cube.png'),
            centerDialogVisible: false,
            showClo: false,
            checked: false,
        };
    },
    mounted() {
        for (let i = 0; i < product.length; i++) {
            this.productarr.push(product.slice(i, (i += 4)));
        }
        this.helpList.push(
            help.filter((item, index) => {
                return index < 4;
            })
        );
        this.helpList.push(
            help.filter((item, index) => {
                return index > 3 && index < 7;
            })
        );
        sessionStorage.clear();
        this.instance = this.$store.state.instance;
        this.baseUrl = location.origin;
        let port = location.port;
        if (port) {
            this.href = 'https://passport.qatest.baidu.com/v2/?reg&tpl=&u=' + this.baseUrl;
        } else {
            this.href = 'https://passport.baidu.com/v2/?reg&tpl=&u=' + this.baseUrl;
        }
        if (!this.instance) {
            // eslint-disable-next-line no-undef
            this.instance = passport.pop.init({
                loginVersion: 'v4',
                apiOpt: {
                    staticPage: this.baseUrl + '/v3Jump.html',
                    product: 'copyright',
                    u: this.baseUrl + '/',
                    memberPass: true,
                    safeFlag: 0,
                    userPwdLogin: 1,
                },
                cache: true,
                forgetLink: 'https://passport.baidu.com/?getpass_index',
                registerLink: 'https://passport.baidu.com/v2/?reg&tpl=&u=' + this.baseUrl,
                authsite: ['tsina', 'weixin', 'qzone'],
                tangram: true,
            });
            this.$store.commit('islogin', this.instance);
        }
        check().then((res) => {
            const { data } = res;
            if (res.data !== null) {
                this.userData = data;
                sessionStorage.setItem('user', JSON.stringify(data));
            } else {
                sessionStorage.removeItem('user');
                this.userData = null;
            }
        });
    },
    methods: {
        login() {
            if (this.instance) {
                this.instance.show();
            }
        },
        account() {
            this.$router.push({
                path: '/record',
            });
        },
        exit() {
            let port = location.port;
            if (port) {
                window.location.href = 'https://passport.qatest.baidu.com/?logout&u=' + this.baseUrl;
            } else {
                window.location.href = 'https://passport.baidu.com/?logout&u=' + this.baseUrl;
            }
        },
        sendComplaint() {
            checkAgree().then((res) => {
                if (!res.data) {
                    const userInfo = JSON.parse(sessionStorage.getItem('user'));
                    const { display_name, face_url } = userInfo || {};
                    if (display_name && face_url) {
                        this.centerDialogVisible = true;
                    } else {
                        this.login();
                    }
                } else {
                    this.$router.push({
                        path: '/subject',
                    });
                }
            });
        },
        agreeChange(ok) {
            if (ok) {
                this.centerDialogVisible = false;
                agree().then((res) => {
                    if (res.code === 200) {
                        this.$router.push({
                            path: '/subject',
                        });
                    }
                });
            } else {
                this.centerDialogVisible = false;
            }
        },
        goRecord() {
            const userInfo = JSON.parse(sessionStorage.getItem('user'));
            const { display_name, face_url } = userInfo || {};
            if (display_name && face_url) {
                this.$router.push({
                    path: '/record',
                });
            } else {
                this.login();
            }
        },
        keyupBgc() {
            this.class = 'btn_click';
        },
    },
};
</script>

<style lang="less" scoped>
@import '../assets/css/homewel.less';
@import '../assets/css/agreement.css';
/deep/ .el-dialog {
    width: 792px;
    height: 672px;
    padding-left: 20px;
    padding-right: 20px;
}
.dialog-text a {
    color: rgb(34, 164, 230);
}
.welcome_head_reight a:hover {
    cursor: pointer;
    color: #2d63e0;
}

.user_login {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.el-dropdown {
    margin-left: 10px;
}

.el-dropdown-menu__item {
    width: 60px;
}
</style>