/**
 * @FilePath: /copyright/src/main.js
 * @Description: 添加文件描述~~~
 * @Author: jiangshengkai@baidu.com
 * @Date: 2023-01-03 16:40:41
 * @LastEditors: jiangshengkai@baidu.com
 * @LastEditTime: 2023-02-07 17:46:07
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/reset.less';
import axios from 'axios';
import { rTime } from '@/filters/index.js';
import { check } from '@/api/getData.js';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Viewer.setDefaults({
    inline: false,
    button: true,
    navbar: false,
    title: false,
    toolbar: false,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: false,
    fullscreen: false,
    keyboard: false,
});

Vue.use(Viewer);
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);
let whiteList = ['/', '/subject', '/complete', '/record', '/redetail', '/help', '/agreement'];
let pathList = [{
    path: '/subject',
    active: 0
}, {
    path: '/hasownership',
    active: 1
}, {
    path: '/ownership',
    active: 1
}, {
    path: '/noownership',
    active: 1
}, {
    path: '/complaint',
    active: 2
}, {
    path: '/verification',
    active: 3
}, {
    path: '/confirm',
    active: 4
}];
router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next();
        return;
    }
    check().then(res => {
        if (res.data === null && from.path !== '/') {
            router.push({
                path: '/'
            });
        }
    });
    let active = sessionStorage.getItem('active');
    let arr = [...whiteList];
    pathList.forEach(item => {
        if (active >= item.active) {
            arr.push(item.path);
        }
    });
    if (arr.indexOf(to.path) !== -1) {
        next();
    } else {
        next({
            path: '/'
        });
    }
});


Vue.filter('rTime', rTime);

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
}).$mount('#app');
