import store from '../store/index';
// 获取url中的参数
export function getRequest(text) {
	var url = text;
	var theRequest = {};
	if (url.indexOf('?') !== -1) {
		var str = url.split('?')[1];
		var strs = str.split('&');
		for (var i = 0; i < strs.length; i++) {
			theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1]);
		}
	}
	return theRequest;
}

export function getKey(key) {
	let proveArr = [{
		name: '作品登记证书',
		value: 'registryBook'
	}, {
		name: '平台首发截图',
		value: 'releaseScreenshot'
	}, {
		name: '影视公映许可证或出品方署名截图',
		value: 'publicScreenshot'
	}, {
		name: '软件著作权登记证书',
		value: 'copyrightBook'
	}, {
		name: '游戏ISBN号核发单',
		value: 'ISBNNum'
	}, {
		name: '版权授权证明文件',
		value: 'copyrightFile'
	}, {
		name: '其他权属证明文件',
		value: 'otherFile'
	}];
	let str = '...';
	proveArr.forEach(item => {
		if (item.name === key) {
			str = item.value;
		}
	});
	return str;
}

export function getKey2(key) {
	let proveArr = [{
		name: '作品登记证书',
		value: 'registryBook'
	}, {
		name: '平台首发截图',
		value: 'releaseScreenshot'
	}, {
		name: '影视公映许可证或出品方署名截图',
		value: 'publicScreenshot'
	}, {
		name: '软件著作权登记证书',
		value: 'copyrightBook'
	}, {
		name: '游戏ISBN号核发单',
		value: 'ISBNNum'
	}, {
		name: '版权授权证明文件',
		value: 'copyrightFile'
	}, {
		name: '其他权属证明文件',
		value: 'otherFile'
	}];
	let str = '...';
	proveArr.forEach(item => {
		if (item.value === key) {
			str = item.name;
		}
	});
	return str;
}

export function rTime(date) {
	var json_date = new Date(date).toJSON();
	let str = new Date(+new Date(json_date) + 8 * 3600 * 1000).toISOString();
	return str.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').split(' ')[0];
}


export function getType(type) {
	let obj = store.getters.getOptionsObj; // 获取作品类型列表
	for (var key in obj) {
		if (type === key + '') {
			return obj[key];
		}
	}
}

export function getType2(type) {
	let obj = store.getters.getOptionsObj; // 获取作品类型列表
	for (var key in obj) {
		if (type === obj[key] + '') {
			return key;
		}
	}
}

export function setLocalStorage(key, value) {
	var curtime = new Date().getTime(); // 获取当前时间 ，转换成JSON字符串序列
	var valueDate = JSON.stringify({
		val: value,
		timer: curtime
	});
	try {
		localStorage.setItem(key, valueDate);
	} catch (e) {
		/* if(e.name === 'QUOTA_EXCEEDED_ERR' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
			console.log('Error: 本地存储超过限制');
			localStorage.clear();
		} else {
			console.log('Error: 保存到本地存储失败');
		} */
		// 兼容性写法
		if (isQuotaExceeded(e)) {
			console.log('Error: 本地存储超过限制');
			localStorage.clear();
		} else {
			console.log('Error: 保存到本地存储失败');
		}
	}
}

function isQuotaExceeded(e) {
	var quotaExceeded = false;
	if (e) {
		if (e.code) {
			switch (e.code) {
				case 22:
					quotaExceeded = true;
					break;
				case 1014: // Firefox
					if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
						quotaExceeded = true;
					}
					break;
			}
		} else if (e.number === -2147024882) { // IE8
			quotaExceeded = true;
		}
	}
	return quotaExceeded;
}

export function getLocalStorage(key) {
	var exp = 1000 * 60 * 60 * 24 * 7;
	if (localStorage.getItem(key)) {
		var vals = localStorage.getItem(key); // 获取本地存储的值
		var dataObj = JSON.parse(vals); // 将字符串转换成JSON对象
		// 如果(当前时间 - 存储的元素在创建时候设置的时间) > 过期时间
		var isTimed = (new Date().getTime() - dataObj.timer) > exp;
		if (isTimed) {
			console.log('存储已过期');
			localStorage.removeItem(key);
			return null;
		} else {
			var newValue = dataObj.val;
		}
		return newValue;
	} else {
		return null;
	}
}

// // 处理导出流    name  活动名称
// export function exportExcelStream(res, name) {
//   //创建一个隐藏的a连接
//   const link = document.createElement('a');
//   let blob = new Blob([res.data], {
//     type: 'application/vnd.ms-excel'
//   });
//   link.style.display = 'none';
//   // 设置连接
//   link.href = URL.createObjectURL(blob);
//   link.setAttribute('download', name + '.xlsx') // 设置下载文件名称
//   document.body.appendChild(link);
//   // 模拟点击事件
//   link.click();
//   document.body.removeChild(link)
// }

// 防抖函数
export function debounce(fn, delay) {
	let timer;
	return function (...args) {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			fn.apply(this, args);
		}, delay);
	};
}
export function isPhone(rule, value, callback) {
	const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
	if (value === '' || value === undefined || value === null) {
		callback();
	} else {
		if ((!reg.test(value)) && value !== '') {
			callback(new Error('请输入正确的电话号码'));
		} else {
			callback();
		}
	}
}
export function telStart(phone) {
	phone = phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
	return phone;
}

/**
 * 利用 document.title 做新消息提示
 * @export
 * @param {Number} count
 */
export function titleNotify(count) {
	const hasNewMessage = count > 0;
	if (hasNewMessage) {
		if (document.title.search(/\((.*?)\)/) >= 0) {
			document.title = document.title.replace(/\((.*?)\)/, `(${count > 99 ? '99+' : count})`);
		} else {
			document.title = `(${count})${document.title}`;
		}
	} else {
		document.title = document.title.replace(/\((.*?)\)/, '');
	}
}
