export default [{
                value: 1401,
                label: '百度搜索',
                img: require('@/assets/img/search.png')
              }, {
                value: 1402,
                label: '百度网盘',
                img: require('@/assets/img/disk.png')
              }, {
                value: 1408,
                label: '好看视频',
                img: require('@/assets/img/look.png')
              }, {
                value: 1410,
                label: '百家号',
                img: require('@/assets/img/hundred.png')
              }, {
                value: 1407,
                label: '百度APP',
                img: require('@/assets/img/app.png')
              }, {
                value: 1405,
                label: '百度知道',
                img: require('@/assets/img/know.png')
              }, {
                value: 1403,
                label: '百度文库',
                img: require('@/assets/img/library.png')
              }, {
                value: 1404,
                label: '百度贴吧',
                img: require('@/assets/img/postbar.png')
              }, {
                value: 1406,
                label: '百度图片',
                img: require('@/assets/img/picture.png')
              }, {
                value: 1409,
                label: '度小视',
                img: require('@/assets/img/videologo.png')
              }, {
                value: 1412,
                label: '百度手机浏览器',
                img: require('@/assets/img/browser.png')
              }];